<template>

    <div class="col-lg-6 right-panel">
        <div class="w-100">
            <h2 class="mx-auto font-weight-bold">Tilaa</h2>
        </div>
        <b-form @submit="onSubmit">

            <!-- Etunimi -->
            <b-form-group id="etunimiGroup" label="Etunimi" label-for="etunimi">
                <b-form-input id="etunimi" type="text" v-model="form.firstname" required placeholder="Etunimi">
                </b-form-input>
            </b-form-group>

            <!-- Sukunimi -->
            <b-form-group id="sukunimiGroup" label="Sukunimi" label-for="sukunimi">
                <b-form-input id="sukunimi" type="text" v-model="form.lastname" required placeholder="Sukunimi">
                </b-form-input>
            </b-form-group>

            <!-- Sposti -->

            <b-form-group id="spostiGroup" label="Sähköpostiosoite" label-for="spostiGroup">
                <b-form-input id="spostiGroup" type="email" v-model="form.email" required placeholder="Sähköposti">
                </b-form-input>
            </b-form-group>


            <!-- Osoite -->

            <!-- Katuosoite -->
            <b-form-group id="osoiteGroup" label="Katuosoite" label-for="osoite">
                <b-form-input id="osoite" type="text" v-model="form.address" required placeholder="Osoite" @blur.native="getGeoCode">
                </b-form-input>
            </b-form-group>


            <!-- Postinumero -->
            <b-form-group id="postinumeroGroup" label="Postinumero" label-for="postinumero">
                <b-form-input id="postinumero" type="text" v-model="form.postalcode" required placeholder="Postinumero" @blur.native="getGeoCode">
                </b-form-input>
            </b-form-group>

            <!-- Ovikoodi -->
            <b-form-group id="ovikoodiGroup" label="Ovikoodi" label-for="ovikoodi">
                <b-form-input id="ovikoodi" type="text" v-model="form.door" placeholder="Ovikoodi tai summeri">
                </b-form-input>
            </b-form-group>


            <!-- Puhelinnumero -->
            <b-form-group id="puhelinnumeroGroup" label="Puhelinnumero" label-for="puhelinnumero">
                <b-form-input id="puhelinnumero" type="text" v-model="form.phone" required placeholder="+358...">
                </b-form-input>
            </b-form-group>




            <!-- Toivottu saapumisaika -->

            <b-form-group id="vierailuaikaGroup" label="Toivottu vierailuaika:" label-for="vierailuaika">
                <b-form-select id="vierailuaika" :options="ajat" required v-model="form.time">
                    <template slot="first">
                        <!-- this slot appears above the options from 'options' prop -->
                        <option :value="null" disabled>Valitse toivottu vierailuaika</option>
                    </template>

                </b-form-select>
            </b-form-group>

            <!-- Lisätoivomuksia -->
            <b-form-group label="Lisätoiveet pukille:" label-for="lisaa">
                <b-form-textarea id="lisaa" v-model="form.additional" placeholder="Nimi ovessa mikäli eri kuin tilaajalla, paikalla olevat lapset, harrastukset, erityinen lahjatoive jne" :rows="3" :max-rows="6">
                </b-form-textarea>
            </b-form-group>

            <b-button type="submit" variant="primary">Lähetä</b-button>

        </b-form>

    </div>
</template>

<script>
    import axios from 'axios';


    export default {
        data() {
            return {
                ajat: [
                    '14-15', '15-17', '17-19', '19-20'
                ]
            }
        },
        props: {
            form: {
                type: Object,
                default: function() {
                    return {};
                }
            },
        },
        methods: {
            getGeoCode() {

                if (this.form.address != '' && this.form.postalcode != '') {
                    axios.get('https://geocode.search.hereapi.com/v1/geocode', {
                        params: {
                            apiKey: 'wqtV6yQCn41PksnLY-9hSJJUdxvfsAhu9rL-l2J8AC8',
                            //app_id: '9lC7Ki9quUUYhQx58hb1',
                            //app_code: 'K3pWwAAURsFCRkKpw9QKaA',
                            q: this.form.address + ' ' + this.form.postalcode + " Finland"
                        }
                    }).then(res => {
                        console.log(res);

                        var results = res.data['items']

                        if (results.length > 0) {
                            console.log('results', results);

                            this.form.lat = results[0]['position']['lat'];
                            this.form.lon = results[0]['position']['lng'];
                            //
                        }

                    }).catch(e => console.log(e));
                }

            },
            onSubmit(evt) {
                evt.preventDefault();

                this.$emit('onSubmit');

            }
        },
        components: {}
    }

</script>

<style>
    .right-panel {
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

</style>
